<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="generalSheet in generalSheets"
        :key="generalSheet?.id"
        class="d-flex align-items-center"
      >
        <p>
          {{ generalSheet?.general_sheet_template?.name }}:
          <span class="status" :class="parseStatus(generalSheet?.status)">
            {{ generalSheet?.status }}
          </span>
        </p>
        <div class="ml-auto d-flex">
          <Icon tooltip="Anexar/abrir arquivos" class="mr-2" placement="bottom">
            <b-button
              variant="light"
              class="p-0"
              @click="openFilesModal(generalSheet.id)"
            >
              <File class="icon" />
            </b-button>
          </Icon>
          <Icon
            :tooltip="fillSheetTooltip(generalSheet)"
            class="mr-2"
            placement="bottom"
          >
            <b-button
              variant="light"
              class="p-0"
              :disabled="isDefaultSheet(generalSheet)"
              @click="openModal(generalSheet)"
            >
              <Edit class="icon" />
            </b-button>
          </Icon>
          <Icon tooltip="Imprimir" placement="bottom">
            <b-button
              variant="light"
              class="p-0"
              @click="
                printDocument(
                  generalSheet.general_sheet_template.path,
                  generalSheet.id
                )
              "
            >
              <Printer class="icon" />
            </b-button>
          </Icon>
        </div>
      </b-list-group-item>
    </b-list-group>

    <CompanionModal
      v-bind="$props"
      :updateSurgeryRecord="updateSurgeryRecord"
    />

    <PatientModal
      v-bind="$props"
      :updateSurgeryRecord="updateSurgeryRecord"
      :generalSheetId="generalSheetId"
    />

    <GeneralSheetFilesModal v-bind="$props" :generalSheetId="generalSheetId" />
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { getDocUrl } from '@/utils/PDFHelper'

export default {
  name: 'GeneralSheets',
  props: {
    surgeryInformationId: String,
    patientId: String
  },
  components: {
    Icon: () => import('@/components/General/Icon'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Printer: () => import('@/assets/icons/printer.svg'),
    File: () => import('@/assets/icons/file.svg'),
    CompanionModal: () =>
      import('@/components/SurgeryCenter/Dashboard/Modals/CompanionModal'),
    PatientModal: () =>
      import('@/components/SurgeryCenter/Dashboard/Modals/PatientModal'),
    GeneralSheetFilesModal: () =>
      import(
        '@/components/SurgeryCenter/Dashboard/Modals/GeneralSheetFilesModal'
      )
  },
  data: () => ({
    generalSheets: [],
    isLoading: false,
    clinic: getCurrentClinic(),
    generalSheetId: null
  }),
  async created() {
    await this.getSurgeryRecords()
  },
  methods: {
    getDocUrl,
    async printDocument(path, generalSheetId) {
      this.generalSheetId = generalSheetId
      try {
        await this.updateSurgeryRecord('Impresso')
        await getDocUrl(path)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getSurgeryRecords() {
      this.isLoading = true
      try {
        const { data } = await this.api.getSurgeryRecords(
          this.surgeryInformationId,
          this.clinic.id
        )
        this.generalSheets = data
        this.$emit('generalSheetsUpdated')
      } catch (error) {
        this.$toast.error('Erro ao buscar fichas gerais')
      } finally {
        this.isLoading = false
      }
    },
    async updateSurgeryRecord(status) {
      try {
        await this.api.updateSurgeryRecord(this.generalSheetId, {
          status
        })
        const index = this.generalSheets.findIndex(
          generalSheet => generalSheet.id === this.generalSheetId
        )
        this.$set(this.generalSheets, index, {
          ...this.generalSheets[index],
          status
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    isDefaultSheet(generalSheet) {
      return Boolean(generalSheet.general_sheet_template.can_edit)
    },
    fillSheetTooltip(generalSheet) {
      return this.isDefaultSheet(generalSheet)
        ? 'Apenas fichas do sistema podem ser preenchidas'
        : 'Preencher'
    },
    parseStatus(status) {
      return status.replace(' ', '_').toLowerCase()
    },
    openModal(generalSheet) {
      const { name } = generalSheet.general_sheet_template
      this.generalSheetId = generalSheet.id
      const modal = {
        'Identificação do paciente': 'surgery-patient-modal',
        'Identificação do acompanhante': 'companion-modal'
      }[name]
      this.$bvModal.show(modal)
    },
    openFilesModal(id) {
      this.generalSheetId = id
      this.$bvModal.show('general-sheet-files-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  border-right: 0;
  border-top: 0;
  border-left: 0;
  height: 6vh;

  p {
    color: var(--type-active);
    font-weight: 700;
    font-size: min(1.2vw, 16px);

    .status {
      font-size: min(2.6vw, 18px) !important;

      &.a_imprimir {
        color: var(--type-placeholder) !important;
      }

      &.impresso {
        color: var(--blue-500) !important;
      }

      &.arquivos_anexados,
      &.preenchido {
        color: var(--states-success) !important;
      }

      &.a_preencher {
        color: var(--light-orange-500) !important;
      }
    }
  }

  .icon {
    width: 25px;
    height: 25px;
  }
}

button {
  background-color: transparent !important;
  border: none !important;
}
</style>
